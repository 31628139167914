/* TimingErrorWidget.css */

/* .timing-error-widget {
    position: relative;
    width: 320px; /* Adjust based on your needs */
/*    height: 40px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
} */

.range {
    height: 100%;
}

.red {
    background-color: red;
    width: 20%; /* Adjust based on range */
}

.yellow {
    background-color: orange;
    width: 20%; /* Adjust based on range */
}

.green {
    background-color: green;
    width: 20%; /* Adjust based on range */
}

.error-marker {
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
}
.timing-error-chart {
    position: relative;
    width: 100%;
    height: 400px; /* Adjust height as needed */
    border: 1px solid #ccc;
    background: #f5f5f5;
}

.error-point {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.timing-error-chart {
    position: relative;
    width: 100%;
    height: 400px; /* Adjust height as needed */
    border: 1px solid #ccc;
    background: #f5f5f5;
}


.mean-line, .std-dev-line {
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
}

.mean-line {
    background-color: blue;
}

.std-dev-line {
    background-color: purple;
}

.label {
    position: absolute;
    color: black;
    font-size: 12px;
    transform: translateX(-50%); /* Center the label horizontally */
}
