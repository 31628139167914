@tailwind base;
@tailwind components;
@tailwind utilities;

/* Note Error Widget */
.note-error-widget {
    @apply grid grid-cols-6 grid-rows-4 gap-2 p-4 bg-white rounded-lg shadow-lg;
    transition: background-color 0.2s ease-in-out;
}

.note-cell {
    @apply flex flex-col items-center justify-center border border-gray-300 rounded-lg p-2;
    width: 100%; /* Set a fixed width for the cells */
}

.note-cell.green {
    @apply bg-green-300;
}

.note-cell.yellow {
    @apply bg-yellow-300;
}

.note-cell.orange {
    @apply bg-orange-300;
}

.note-cell.red {
    @apply bg-red-300;
}

.note-cell.pulse {
    animation: pulse 0.2s;
    @apply bg-red-400;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        background-color: red;
    }
    50% {
        transform: scale(1.2);
        background-color: darkred;
    }
    100% {
        transform: scale(1);
        background-color: red;
    }
}

.note-cell span {
    @apply text-center text-gray-800;
    white-space: nowrap; /* Prevent text from wrapping */
}
