@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom global styles (if needed) */
.nav {
    @apply bg-gray-400 text-white p-4 shadow-lg; /* Use shadow-lg for a larger shadow */
}

.nav-link {
    @apply hover:text-gray-800 transition-colors px-4 py-2; /* Add padding to increase space between items */
}

.nav-container {
    @apply container mx-auto flex justify-between items-center;
}
.logout-button {
    @apply bg-gray-400 text-white p-4 shadow-lg hover:text-gray-800 transition-colors ; /* Use shadow-lg for a larger shadow */
}

.login-container {
    @apply max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg;
}

.login-title {
    @apply text-2xl font-bold text-center text-gray-800 mb-6;
}

.login-form {
    @apply flex flex-col space-y-4;
}

.form-group {
    @apply flex flex-col;
}

.form-label {
    @apply text-sm font-medium text-gray-700 mb-2;
}

.form-input {
    @apply px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500;
}

.login-button {
    @apply w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-700 transition-colors;
}

.google-login-container {
    @apply mt-6 text-center;
}

.google-login-title {
    @apply text-lg font-medium text-gray-800 mb-4;
}

.google-login-button {
    @apply w-full flex justify-center;
}
/* Sight Reading Container */
.sight-reading-container {
    @apply max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg;
}

/* Title Styling */
.title {
    @apply text-3xl font-bold text-center text-gray-800 mb-6;
}

/* Controls Section */
.controls {
    @apply flex items-center space-x-4 mb-6;
}

/* Control Button Styling */
.control-button {
    @apply px-4 py-2 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-700 transition-colors;
}

/* BPM Control */
.bpm-control {
    @apply flex items-center space-x-2;
}

.bpm-label {
    @apply text-gray-700 font-medium;
}

.bpm-input {
    @apply w-20 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500;
}

/* Timing Error Widget */
.timing-error-widget {
    @apply relative w-full h-40 mt-8 mb-6 bg-gray-100 rounded-lg shadow-inner flex items-center justify-center;
}

/* User Input Events */
.notation-wrapper {
    @apply mt-6 p-4 bg-gray-50 rounded-lg shadow-inner overflow-x-auto;
}
.home-container {
    @apply max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg;
}

.home-header {
    @apply text-center mb-8;
}

.home-title {
    @apply text-4xl font-bold text-indigo-700 mb-2;
}

.home-subtitle {
    @apply text-lg text-gray-600;
}

.home-section {
    @apply mb-8;
}

.section-title {
    @apply text-2xl font-semibold text-gray-800 mb-4;
}

.section-content {
    @apply text-gray-700 mb-4;
}

.section-list {
    @apply list-disc pl-5 text-gray-700;
}

.section-list-item {
    @apply mb-2;
}

.home-footer {
    @apply text-center mt-10;
}

.footer-text {
    @apply text-sm text-gray-500;
}
.dataset-control {
    @apply flex items-center space-x-2;
}

.dataset-label {
    @apply text-gray-700 font-medium;
}

.dataset-select {
    @apply w-40 px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500;
}
.link {
    @apply text-blue-500 hover:text-blue-700 hover:underline;
}